import React from "react";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import InConstruction from "./components/in-construction/InConstruction";

function App() {
  return (
    <>
      {/* <Header /> */}
      {/* <Home /> */}
      <InConstruction />
    </>
  );
}

export default App;
