import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraBaseProvider } from "@chakra-ui/react";

import { hydrateRoot } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <ChakraBaseProvider>
      <App />
    </ChakraBaseProvider>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ChakraBaseProvider>
      <App />
    </ChakraBaseProvider>
  );
}
