import React from "react";
import "../../App.css";

export default function InConstruction() {
  return (
    <div className="App" id="area4">
      <header className="App-header">
        <img src="./logo-svg.svg" className="App-logo" alt="logo" />
        <img src="./floret-name.svg" alt="logo" className="App-logo-name" />
        <p>Em breve uma inovadora mentoria de desenvolvimento pessoal</p>
      </header>
    </div>
  );
}
